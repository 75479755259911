import { Dispatch } from 'react'
import { sessionToken } from '../../../authServices/jwt'
import { capitalizingSentence } from '../../helpers'
import { IActionType } from '../../interface/commonInterface'
import {
	getLoginPending,
	getLoginSuccess,
	getLoginFailed,
	getResetTokenPending,
	getResetTokenSuccess,
	getResetTokenFailed,
	resetPasswordPending,
	resetPasswordSuccess,
	resetPasswordFailed,
	getLogoutPending,
	getLogoutSuccess,
	getLogoutFailed,
	refreshTokenPending,
	refreshTokenSuccess,
	refreshTokenFailed,
    confirmResetPasswordCodePending,
    confirmResetPasswordCodeSuccess,
    confirmResetPasswordCodeFailed,
    getResetPasswordCodePending,
    getResetPasswordCodeSuccess,
    getResetPasswordCodeFailed
} from '../action/authAction/authAction'

import {
	getLoginEffect,
	getResetTokenEffect,
	resetPasswordEffect,
	getLogoutEffect,
	refreshTokenEffect,
    confirmResetPasswordCodeEffect,
    getResetPasswordCodeEffect
} from '../effect/authEffect'

import {
	ILoginEffect,
	IResetPasswordEffect,
	IResetTokenEffect
} from '../effect/interface'

import { Role } from '../../../constant/role'
import { getAuthSuccess } from '../action/authAction/authAction'

export const getLogin = async (dispatch: Dispatch<IActionType>, data: ILoginEffect) => {
	dispatch(getLoginPending());
	const res = await getLoginEffect(data);

	if (res && res.status >= 200 && res.status <= 300) {
		const { access_token, refresh_token } = res.data;
		const tokenParts = access_token.split('.');
		const payload = JSON.parse(atob(tokenParts[1]));
		
		const hasSuperUserRole = payload.realm_access?.roles?.includes('twc-super-user');
		if (!hasSuperUserRole) {
			dispatch(getLoginFailed('Unauthorized access'));
			return {
				result: false,
				message: 'You do not have permission to access this application'
			};
		}

		sessionToken.setToken(access_token);
		sessionToken.setRefreshToken(refresh_token);
		localStorage.setItem("TENANT_ID", payload?.tenantid);
		dispatch(getLoginSuccess(access_token));
		dispatch(getAuthSuccess({
			roles: [{ id: Role.admin }],
			...payload
		}));
		return {
			result: true,
			message: 'Login Successful'
		};
	}
	dispatch(getLoginFailed(res?.data?.message || 'Something went wrong'));
	return {
		result: false,
		message: res?.data?.message || 'Something Went Wrong'
	};
};

export const getResetToken = async (dispatch: Dispatch<IActionType>, data: IResetTokenEffect) => {
	dispatch(getResetTokenPending())
	const res = await getResetTokenEffect(data)
	const notification = capitalizingSentence(res?.data?.message)

	if (res && res.status >= 200 && res.status <= 300) {
		const resetToken = res?.data?.data
		dispatch(getResetTokenSuccess(resetToken || ''))
		return {
			result: true,
			message: notification || 'Get Reset Token Successfully'
		}
	}
	dispatch(getResetTokenFailed(notification || 'Something went wrong'))
	return {
		result: false,
		message: notification || 'Cannot Get Reset Token'
	}
}

export const resetPassword = async (dispatch: Dispatch<IActionType>, data: IResetPasswordEffect) => {
	dispatch(resetPasswordPending())
	const res = await resetPasswordEffect(data)
	const notification = capitalizingSentence(res?.data?.message)

	if (res && res.status >= 200 && res.status <= 300) {
		dispatch(resetPasswordSuccess())
		return {
			result: true,
			message: notification || 'Update Password Successfully'
		}
	}
	dispatch(resetPasswordFailed(notification || 'Something went wrong'))
	return {
		result: false,
		message: notification || 'Cannot Reset Password'
	}
}

export const getLogout = async (dispatch: Dispatch<IActionType>) => {
	dispatch(getLogoutPending());
	sessionToken.clearToken();
	sessionToken.clearRefreshToken();
	localStorage.removeItem("KEYCLOAK_ACCESS_TOKEN");
	localStorage.removeItem("TENANT_ID");
	dispatch(getLogoutSuccess());
	return {
		result: true,
		message: 'Sign Out Successfully'
	};
};

export const refreshToken = async (dispatch: Dispatch<IActionType>) => {
	dispatch(refreshTokenPending());
	const res = await refreshTokenEffect();
	
	if (res && res.status >= 200 && res.status <= 300) {
		const { access_token, refresh_token } = res.data;
		const tokenParts = access_token.split('.');
		const payload = JSON.parse(atob(tokenParts[1]));
		
		const hasSuperUserRole = payload.realm_access?.roles?.includes('twc-super-user');
		if (!hasSuperUserRole) {
			sessionToken.clearToken();
			sessionToken.clearRefreshToken();
			dispatch(refreshTokenFailed('Unauthorized access'));
			return {
				result: false,
				message: 'You do not have permission to access this application'
			};
		}

		sessionToken.setToken(access_token);
		sessionToken.setRefreshToken(refresh_token);
		dispatch(refreshTokenSuccess(access_token));
		dispatch(getAuthSuccess({
			roles: [{ id: Role.admin }],
			...payload
		}));
		return {
			result: true,
			message: 'Token Refreshed'
		};
	}
	
	sessionToken.clearToken();
	sessionToken.clearRefreshToken();
	dispatch(refreshTokenFailed(res?.data?.message || 'Something went wrong'));
	return {
		result: false,
		message: res?.data?.message || 'Something Went Wrong'
	};
};

export const getResetPasswordCode = async (
    dispatch: Dispatch<IActionType>,
    email: string
) => {
    dispatch(getResetPasswordCodePending());
    const res = await getResetPasswordCodeEffect(email);
    const notification: string = capitalizingSentence(res?.data?.message);
    if (res && res.status >= 200 && res.status <= 300) {
        dispatch(getResetPasswordCodeSuccess());
        return {
            result: true,
            message: notification || "Please check your email",
        };
    }
    dispatch(
        getResetPasswordCodeFailed(notification || "Email Does Not Exists")
    );
    return {
        result: false,
        message: notification || "Email Does Not Exists",
    };
};

export const confirmResetPasswordCode = async (
    dispatch: Dispatch<IActionType>,
    token: string
) => {
    dispatch(confirmResetPasswordCodePending());
    const res = await confirmResetPasswordCodeEffect(token);
	localStorage.setItem('verify_code', token);
    const notification: string = capitalizingSentence(res?.data?.message);
    if (res && res.status >= 200 && res.status <= 300 && res.data?.data) {
        dispatch(confirmResetPasswordCodeSuccess(token));
        return {
            result: true,
            message: notification || "",
        };
    }
    dispatch(confirmResetPasswordCodeFailed(notification || "Wrong verify code"));
    return {
        result: false,
        message: notification || "Wrong verify code",
    };
};