// import { ConfigurationIcon } from "../../assets/icon/ConfigurationIcon";

interface category {
	id?: string;
	key?: string;
	icon?: React.ReactNode;
	label?: string;
	items?: subCategory[];
}

interface subCategory {
	key?: string;
}

export const categories: category[] = [
	{
		id: "2",
		key: "retailers-management",
		// icon: <ShopOutlined />,
	},
	{
		id: "3",
		key: "staff-management",
		// icon: <UsergroupAddOutlined />,
	},
	{
		id: "4",
		key: "setup-email-template",
		// icon: <MailOutlined />,
	},
	{
		id: "5",
		key: "sendgrid-config",
	},
	{
		id: "8",
		key: "logout",
		// icon: <LogoutOutlined />,
		// items: [{ key: "sign-out" }],
		items: [],
	},
];

export const categories_AppConfig: category[] = [
	{
		id: "3",
		key: "app-configuration",
		// icon: <ConfigurationIcon />,
		items: [
			{ key: "launch-point" },
			{ key: "add-items-to-wishlist" },
			{ key: "wishlist-page" },
			{ key: "notifications" },
		],
	},
]
